import { TheWestSection } from '@news-mono/common'
import { StaticRoute, StaticRoutes } from '@news-mono/web-common'
import { getSwgLandingPage } from '../regionals/get-swg-landing-page'

const createUniversityOfferPage: StaticRoute<TheWestSection> = (services) => {
    if (!services.store.getState().toggles['university-offering']) return null
    return getSwgLandingPage({
        section: 'default',
        getAdTargeting: services.getAdTargeting,
        toggles: services.store.getState().toggles,
        options: {
            modifier: 'university-offer',
            noIndex: true,
        },
    })
}

export const getUniversityOfferRouteInfo: StaticRoutes<TheWestSection> = {
    '/university-offer': createUniversityOfferPage,
}
