import { areaCodeSchema } from '@west-australian-newspapers/election-api-types'
import { ResolveSlugElectionPageType } from '@west-australian-newspapers/publication-types'
import { AdPageKind } from '../../advertising'
import { GetRouteAdTargeting } from '../../routing'
import { ElectionDefinition } from './electionTypes'

/**
 * Build the election page kind from the election id.
 *
 * @param electionId - election id to determien from
 * @returns
 */
export const getElectionAdPageKindFromElectionId = (
    electionId: string,
): AdPageKind | null => {
    const splitId = electionId.split('-')
    const [area, _, year] = splitId
    const upperArea = area === 'federal' ? 'Federal' : area.toUpperCase()
    const safeParseAreaCode = areaCodeSchema.safeParse(upperArea)
    const yearNum = Number(year)

    // Make sure it's a valid area code
    if (!safeParseAreaCode.success) {
        console.error(
            `Election id ${electionId} is not a valid area code: ${safeParseAreaCode.error}`,
        )
        return null
    }
    if (isNaN(yearNum)) {
        console.error(`Election id ${electionId} is not a valid year: ${year}`)
        return null
    }

    return `Politics/${safeParseAreaCode.data}Election${yearNum}/home`
}

export const getElectionAdTargeting = (
    electionDefinition: ElectionDefinition,
    getAdTargeting: GetRouteAdTargeting,
    pageKind: ResolveSlugElectionPageType,
) => {
    const electionPageKind = getElectionAdPageKindFromElectionId(
        electionDefinition.electionId,
    )

    if (electionPageKind == null) {
        throw new Error(
            'Unable to determine the election ad page kind from the provided election id.',
        )
    }

    let adPageId

    if (pageKind === 'landing-page') {
        adPageId = 'home'
    } else {
        adPageId = pageKind.replace('-page', '').replace('-', ' ')
    }

    const adTargeting = getAdTargeting(electionPageKind, 'default', adPageId)

    // Make sure to split the / on the ad units!
    adTargeting.ssAdUnits = adTargeting.ssAdUnits.flatMap((adUnit) =>
        adUnit.split('/'),
    )

    return adTargeting
}
