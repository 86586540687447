// Registrars + Route Builder
import {
    AdUnitRegistration,
    AflFixturesScoreboardWidgetRegistration,
    AflFixturesWidgetRegistration,
    AflInjuriesSuspensionsWidgetRegistration,
    AflMatchLadderWidgetRegistration,
    AflMatchStatsWidgetRegistration,
    AflMatchSummaryWidgetRegistration,
    AflPlayerStatsWidgetRegistration,
    AflScoreBoardRegistration,
    AflSquadsWidgetRegistration,
    AmpGoogleAnalyticsFourRegistration,
    AmpSophiRegistration,
    AmpStickyAdUnitRegistration,
    ArticleHeroRegistration,
    AthenaRegistration,
    BoxRegistration,
    BreachScreenRegistration,
    BreadcrumbRegistration,
    BreakingNewsRegistration,
    CerberusFourRegistration,
    CerberusRegistration,
    ComingSoonRegistration,
    ComponentRendererErrorRegistration,
    ContentSeparatorRegistration,
    CookieConsentUserSettingsPageRegistration,
    CoralRegistration,
    CourtInTheActPageRegistration,
    CuratedPromotionalCardRegistration,
    DevelopingNewsRegistration,
    BusinessDevelopingNewsRegistration,
    DoubletRegistration,
    ePaperPageLatestEditionRegistration,
    ePaperPageReadersAlsoLoveRegistration,
    ePaperPageRegistration,
    FactCheckerSectionHeaderRegistration,
    FailLoadContent404Registration,
    FailLoadContentRegistration,
    FeatureArticleBreachCompositionRegistration,
    FeatureCompositionRegistration,
    FeatureHeadlineRegistration,
    FederalElectionAccordionRegistration,
    FederalElectionWidgetCollectionRegistration,
    FigCaptionRegistration,
    FlourishIframeRegistration,
    GenericInArticleBannerRegistration,
    GuestBookRegistration,
    HeraRegistration,
    HorizontalCricketWidgetRegistration,
    IframeRegistration,
    ImageBannerRegistration,
    InlineContentRegistration,
    JanusRegistration,
    JulietRegistration,
    LimaRegistration,
    LinkBannerRegistration,
    LiveScoresRegistration,
    MatchTimelineWidgetRegistration,
    NewsletterSignupRegistration,
    NovemberRegistration,
    OmnyRegistration,
    OscarRegistration,
    OurPublicationRegistration,
    PaperPromoRegistration,
    ParagraphRegistration,
    PollieRaterBannerRegistration,
    PollieRaterDriverBannerRegistration,
    PollieRaterRegistration,
    PortraitRegistration,
    ProfileCardRegistration,
    ProfileRegistration,
    PromoStrapRegistration,
    PromotionalCardRegistration,
    PublicationRelatedContentRegistration,
    PuzzleIframeRegistration,
    PuzzlesListingRegistration,
    QuickLinksRegistration,
    RealEstateViewRegistration,
    RedirectorRegistration,
    RegionalsSwgPromoPageRegistration,
    RelatedContentRegistration,
    RippleRegistration,
    SearchRegistration,
    SectionHeaderRegistration,
    SharingRegistration,
    SierraRegistration,
    StaticContentRegistration,
    StaticMadMondayPromoCardRegistration,
    StaticSubscribePromoCardRegistration,
    StaticSubscribeVcPromoCardRegistration,
    StaticTradingUpPromoCardRegistration,
    StaticUpLatePromoCardRegistration,
    TaboolaRegistration,
    TabRegistration,
    TelethonCountdownRegistration,
    TheWestArticleRegistration,
    TheWestFeatureRegistration,
    TheWestGalleryRegistration,
    TheWestLiveBillboardRegistration,
    TheWestLivePlayerPageRegistration,
    TheWestPageIsUnavailableRegistration,
    TheWestPageNotFoundRegistration,
    TheWestSomethingWentWrongRegistration,
    TheWestTermsContentRegistration,
    TheWestVideoHubRegistration,
    ThorRegistration,
    TimelineFeedWidgetRegistration,
    TopicListRegistration,
    AFLDraftHeaderRegistration,
    UniformRegistration,
    UpgradeAppRegistration,
    VideoHubRegistration,
    VideoTrailerCardRegistration,
    WebStoriesRegistration,
    WhiskeyRegistration,
    ZeusRegistration,
    TheWestThemeConfig,
    getComponentOverriderRegistration,
    getSchemeOverriderRegistration,
    RetireReadyPromoCardRegistration,
    MedalTallyRegistration,
    OlympicsCollectionRegistration,
    SeatEmbedWidgetCollectionRegistration,
    SeatEmbedWidgetRegistration,
    SeatWidgetRegistration,
    TheRaceWidgetRegistration,
    TWElectionNavigationRegistration,
    FullElectorateWidgetRegistration,
    PartyTotalsRegistration,
    HeadToHeadSeatCountWidgetRegistration,
    MapOverviewWidgetRegistration,
    ElectionFilterContextRegistration,
    PollieRaterInArticleBannerRegistration,
    SportsMasterclassPromoCardRegistration,
} from '@news-mono/component-library'
import {
    componentFeatureToggleMiddleware,
    componentOnEventMiddleware,
    ComponentServices,
    getDataLoaderMiddleware,
    requireEntitlementsMiddleware,
} from '@news-mono/web-common'
import { LayoutRegistration } from 'json-react-layouts'
import { RSSFeedsRegistration } from './routes/static/RSS/RSS.routing'

// Register Components & Collections
export const layout = LayoutRegistration<ComponentServices>()
    .registerComponents((registrar) =>
        registrar
            .registerComponent(ComponentRendererErrorRegistration)
            .registerComponent(FailLoadContentRegistration)
            .registerComponent(FailLoadContent404Registration)

            // collections
            .registerComponent(JulietRegistration)
            .registerComponent(LimaRegistration)
            .registerComponent(SierraRegistration)
            .registerComponent(UniformRegistration)
            .registerComponent(OscarRegistration)
            .registerComponent(NovemberRegistration)
            .registerComponent(WhiskeyRegistration)
            .registerComponent(PortraitRegistration)

            // components
            .registerComponent(AdUnitRegistration)
            .registerComponent(AmpStickyAdUnitRegistration)
            .registerComponent(InlineContentRegistration)
            .registerComponent(TheWestArticleRegistration)
            .registerComponent(TheWestGalleryRegistration)
            .registerComponent(TheWestFeatureRegistration)
            .registerComponent(StaticContentRegistration)
            .registerComponent(StaticTradingUpPromoCardRegistration)
            .registerComponent(StaticUpLatePromoCardRegistration)
            .registerComponent(StaticMadMondayPromoCardRegistration)
            .registerComponent(SectionHeaderRegistration)
            .registerComponent(BreakingNewsRegistration)
            .registerComponent(PromoStrapRegistration)
            .registerComponent(BreadcrumbRegistration)
            .registerComponent(SearchRegistration)
            .registerComponent(IframeRegistration)
            .registerComponent(PuzzleIframeRegistration)
            .registerComponent(ProfileRegistration)
            .registerComponent(TheWestPageNotFoundRegistration)
            .registerComponent(TheWestSomethingWentWrongRegistration)
            .registerComponent(TheWestPageIsUnavailableRegistration)
            .registerComponent(NewsletterSignupRegistration)
            .registerComponent(LinkBannerRegistration)
            .registerComponent(ImageBannerRegistration)
            .registerComponent(PromotionalCardRegistration)
            .registerComponent(ProfileCardRegistration)
            .registerComponent(ArticleHeroRegistration)
            .registerComponent(SharingRegistration)
            .registerComponent(FeatureHeadlineRegistration)
            .registerComponent(FigCaptionRegistration)
            .registerComponent(TopicListRegistration)
            .registerComponent(QuickLinksRegistration)
            .registerComponent(TelethonCountdownRegistration)
            .registerComponent(OmnyRegistration)
            .registerComponent(RelatedContentRegistration)
            .registerComponent(ParagraphRegistration)
            .registerComponent(TaboolaRegistration)
            .registerComponent(RippleRegistration)
            .registerComponent(TheWestLiveBillboardRegistration)
            .registerComponent(PublicationRelatedContentRegistration)
            .registerComponent(ContentSeparatorRegistration)
            .registerComponent(CoralRegistration)
            .registerComponent(TheWestTermsContentRegistration)
            .registerComponent(PaperPromoRegistration)
            .registerComponent(HeraRegistration)
            .registerComponent(RegionalsSwgPromoPageRegistration)
            .registerComponent(PollieRaterRegistration)
            .registerComponent(PollieRaterBannerRegistration)
            .registerComponent(PollieRaterDriverBannerRegistration)
            .registerComponent(PollieRaterInArticleBannerRegistration)
            .registerComponent(RedirectorRegistration)
            .registerComponent(DevelopingNewsRegistration)
            .registerComponent(BusinessDevelopingNewsRegistration)
            .registerComponent(GuestBookRegistration)
            .registerComponent(ComingSoonRegistration)
            .registerComponent(FlourishIframeRegistration)
            .registerComponent(ePaperPageRegistration)
            .registerComponent(ePaperPageLatestEditionRegistration)
            .registerComponent(ePaperPageReadersAlsoLoveRegistration)
            .registerComponent(MedalTallyRegistration)
            .registerComponent(OlympicsCollectionRegistration)

            //afl match centre
            .registerComponent(AflFixturesWidgetRegistration)
            .registerComponent(AflFixturesScoreboardWidgetRegistration)
            .registerComponent(AflInjuriesSuspensionsWidgetRegistration)
            .registerComponent(AflMatchLadderWidgetRegistration)
            .registerComponent(AflMatchStatsWidgetRegistration)
            .registerComponent(AflPlayerStatsWidgetRegistration)
            .registerComponent(AflScoreBoardRegistration)
            .registerComponent(AflSquadsWidgetRegistration)
            .registerComponent(MatchTimelineWidgetRegistration)
            .registerComponent(AflMatchSummaryWidgetRegistration)
            .registerComponent(TimelineFeedWidgetRegistration)

            // internal west components
            .registerComponent(HorizontalCricketWidgetRegistration)
            .registerComponent(LiveScoresRegistration)
            .registerComponent(RSSFeedsRegistration)
            .registerComponent(StaticSubscribePromoCardRegistration)
            .registerComponent(StaticSubscribeVcPromoCardRegistration)
            .registerComponent(VideoTrailerCardRegistration)
            .registerComponent(AFLDraftHeaderRegistration)
            .registerComponent(OurPublicationRegistration)
            .registerComponent(TheWestLivePlayerPageRegistration)
            .registerComponent(CookieConsentUserSettingsPageRegistration)
            .registerComponent(FactCheckerSectionHeaderRegistration)
            .registerComponent(FederalElectionWidgetCollectionRegistration)
            .registerComponent(GenericInArticleBannerRegistration)
            .registerComponent(VideoHubRegistration)
            .registerComponent(TheWestVideoHubRegistration)
            .registerComponent(CourtInTheActPageRegistration)
            .registerComponent(RetireReadyPromoCardRegistration)
            .registerComponent(SportsMasterclassPromoCardRegistration)

            .registerComponent(UpgradeAppRegistration)
            .registerComponent(AmpSophiRegistration)
            .registerComponent(AmpGoogleAnalyticsFourRegistration)

            .registerComponent(PuzzlesListingRegistration)
            .registerComponent(BreachScreenRegistration)
            .registerComponent(CuratedPromotionalCardRegistration)
            .registerComponent(FederalElectionAccordionRegistration)
            .registerComponent(WebStoriesRegistration)
            .registerComponent(RealEstateViewRegistration)

            // Election components
            .registerComponent(TheRaceWidgetRegistration)
            .registerComponent(SeatWidgetRegistration)
            .registerComponent(SeatEmbedWidgetRegistration)
            .registerComponent(SeatEmbedWidgetCollectionRegistration)
            .registerComponent(TWElectionNavigationRegistration)
            .registerComponent(FullElectorateWidgetRegistration)
            .registerComponent(PartyTotalsRegistration)
            .registerComponent(HeadToHeadSeatCountWidgetRegistration)
            .registerComponent(MapOverviewWidgetRegistration)

            .registerMiddleware(componentFeatureToggleMiddleware)
            .registerMiddleware(getDataLoaderMiddleware('component'))
            .registerMiddleware(componentOnEventMiddleware)
            .registerMiddleware(requireEntitlementsMiddleware),
    )
    .registerCompositions((registrar) =>
        registrar
            .registerComposition(AthenaRegistration)
            .registerComposition(ZeusRegistration)
            .registerComposition(ThorRegistration)
            .registerComposition(BoxRegistration)
            .registerComposition(CerberusRegistration)
            .registerComposition(CerberusFourRegistration)
            .registerComposition(JanusRegistration)
            .registerComposition(TabRegistration)
            .registerComposition(DoubletRegistration)
            .registerComposition(FeatureCompositionRegistration)
            .registerComposition(FeatureArticleBreachCompositionRegistration)
            .registerComposition(ElectionFilterContextRegistration)
            .registerComposition(
                getSchemeOverriderRegistration<TheWestThemeConfig>(),
            )
            .registerComposition(
                getComponentOverriderRegistration<TheWestThemeConfig>(),
            )
            .registerMiddleware(componentFeatureToggleMiddleware)
            .registerMiddleware(getDataLoaderMiddleware('composition'))
            .registerMiddleware(componentOnEventMiddleware)
            .registerMiddleware(requireEntitlementsMiddleware),
    )
