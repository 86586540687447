import { WebLink } from '@news-mono/web-common'
import React from 'react'
import {
    StyledPromoImg,
    StyledSportsMasterclassPromoCard,
} from './SportsMasterclassPromoCard.styled'

export const SportsMasterclassPromoCard: React.FC = () => {
    const promo = require('./images/2025-Sports-Masterclass-Campaign-Subscription-Tile.jpg')

    return (
        <StyledSportsMasterclassPromoCard>
            <WebLink to="/features/sports-masterclass#latest-sporting-news">
                <StyledPromoImg
                    src={promo}
                    alt="Sports Masterclass Promo Banner"
                />
            </WebLink>
        </StyledSportsMasterclassPromoCard>
    )
}
