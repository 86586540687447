import { TheWestSection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import {
    ElectionDefinition,
    GetRouteAdTargeting,
    getSocialImageMeta,
    PageType,
    getElectionAdTargeting,
} from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { wrapWithElectionContext } from '../routes'

type CreateElectionPageArgs = {
    getAdTargeting: GetRouteAdTargeting
    electionDefinition: ElectionDefinition
}

export const createElectionPollieRaterPage = ({
    getAdTargeting,
    electionDefinition,
}: CreateElectionPageArgs): PageType<TheWestSection> => {
    const isFederal =
        electionDefinition.electionData?.config?.electionType.toLowerCase() ===
        'federal'
    const electionState = electionDefinition.electionData?.config?.state
    const meta = electionDefinition.meta

    let collectionHeading
    if (isFederal) {
        collectionHeading = 'Latest Federal Election News'
    } else {
        collectionHeading = `Latest ${electionState} Election News`
    }

    const seoTitle = meta.pollieRater?.seoTitle ?? 'Pollie Rater'
    const seoDescription = meta.pollieRater?.seoDescription

    return {
        kind: 'page',
        heading: seoTitle,
        pageType: 'static',
        section: 'default',
        hideHeading: true,
        adTargeting: getElectionAdTargeting(
            electionDefinition,
            getAdTargeting,
            'pollie-rater-page',
        ),
        pageMeta: {
            title: seoTitle,
            description: seoDescription,
            link: meta.canonical
                ? [
                      {
                          rel: 'canonical',
                          href: `${meta.canonical}politics/${electionDefinition.electionId}/pollie-rater`,
                      },
                  ]
                : undefined,
            meta: meta.pollieRater?.socialImage
                ? getSocialImageMeta(
                      meta.pollieRater.socialImage,
                      '2400',
                      '1260',
                  )
                : undefined,
        },
        socialMeta: {
            title: seoTitle,
            description: seoDescription,
        },
        compositions: wrapWithElectionContext([
            layout.nestedComposition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                },
                contentAreas: {
                    main: [
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                verticalSpacing: 'unset',
                                breakpoints: {
                                    lg: {
                                        verticalSpacing: 'unset',
                                        horizontalGuttersOverride: 15,
                                    },
                                },
                            },
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'election-head-to-head-seat-count-widget',
                                        props: {
                                            electionDefinition,
                                        },
                                    }),
                                    layout.component({
                                        type: 'election-navigation',
                                        props: {
                                            electionDefinition,
                                            activePage: 'pollie-rater',
                                            headingLevel: 'h2',
                                        },
                                    }),
                                ],
                            },
                        }),

                        layout.nestedComposition({
                            type: 'athena',
                            props: {
                                containerWidth:
                                    metrics.thewest.siteMetrics
                                        .mainContentWidth,
                                elementType: 'div',
                                sidebarOptions: 'hiddenMobile',
                                hasHorizontalGutters: false,
                            },
                            contentAreas: {
                                sidebar: [
                                    layout.component({
                                        type: 'ad-unit',
                                        props: {
                                            noticePosition: 'above-center',
                                            padding: [
                                                0,
                                                0,
                                                metrics.thewest.margins.md,
                                                0,
                                            ],
                                            slot: {
                                                id: 'sidebar-top',
                                                size: 'desktopMrecHalfPage',
                                                pageType: 'static',
                                            },
                                            adType: 'inline',
                                        },
                                    }),
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {
                                            stickyOffset: 155,
                                            fillContainer: true,
                                        },
                                        contentAreas: {
                                            main: [
                                                layout.component({
                                                    type: 'juliet',
                                                    props: {
                                                        cardType: {
                                                            type: 'landscape',
                                                            format: 'landscape-smallImage',
                                                        },
                                                        removeHorizontalGutters:
                                                            true,
                                                        kickerMode: 'hidden',
                                                        contentIsTangential:
                                                            true,
                                                        sectionHeader: {
                                                            heading:
                                                                collectionHeading,
                                                        },
                                                        dataDefinitionArgs: {
                                                            type: 'curation',
                                                            name: electionDefinition.electionId,
                                                            pageSize: 4,
                                                            offset: 0,
                                                        },
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                ],

                                main: [
                                    layout.component({
                                        type: 'pollie-rater-banner',
                                        props: {},
                                    }),
                                    layout.component({
                                        type: 'pollie-rater',
                                        props: {
                                            electionDefinition,
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                },
            }),
        ]),
    }
}
