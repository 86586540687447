import React from 'react'
import {
    SemiboldText,
    StyledBulletNumbers,
    StyledBulletPointWrapper,
    StyledSubscriptionCopyArea,
    StyledSubscriptionCopyTitle,
    StyledTextLink,
    UniOfferInstructionsDescription,
    UniOfferInstructionsListContainer,
    UniOfferInstructionsTitle,
} from '../RegionalsSwgPromoPage/RegionalsSwgPromoPage.styled'
import { SwgCampaignOfferValues } from './CampaignOffer'

export const getUniversityOfferSwgCampaignValues =
    (): SwgCampaignOfferValues => {
        return {
            isDefault: false,
            creative: 'TW_Subscribe_With_Google_Campaign',
            deal: 'swg-promo',
            type: 'Premium - Inline',
            sku: 'everyday_digital_curtin_university',
            price: {
                weekly: 0.25,
                originalPrice: '$32 every 4 weeks ',
                promoPrice: '$1 every 4 weeks for 12 months ',
                billingTerms: 'T&Cs apply. Min cost $13',
            },
            BenefitListComponent: (
                <>
                    <UniOfferInstructionsTitle>
                        How to Subscribe with Google:
                    </UniOfferInstructionsTitle>
                    <UniOfferInstructionsDescription>
                        Only valid for current students at the following
                        universities: Curtin, ECU, Murdoch, UWA and Notre Dame.
                    </UniOfferInstructionsDescription>
                    <UniOfferInstructionsListContainer>
                        <StyledBulletPointWrapper>
                            <StyledBulletNumbers>1</StyledBulletNumbers>
                            <div>
                                <SemiboldText>Enter</SemiboldText> your student
                                ID and your student email.
                            </div>
                        </StyledBulletPointWrapper>
                        <StyledBulletPointWrapper>
                            <StyledBulletNumbers>2</StyledBulletNumbers>
                            <div>
                                <SemiboldText>Verify</SemiboldText> your email
                                using the link sent to your student email.
                            </div>
                        </StyledBulletPointWrapper>
                        <StyledBulletPointWrapper>
                            <StyledBulletNumbers>3</StyledBulletNumbers>
                            <div>
                                Click{' '}
                                <SemiboldText>
                                    Subscribe with Google
                                </SemiboldText>{' '}
                                and follow the prompts.
                            </div>
                        </StyledBulletPointWrapper>
                        <StyledBulletPointWrapper>
                            <StyledBulletNumbers>4</StyledBulletNumbers>
                            <div>
                                <SemiboldText>Pay</SemiboldText> using your
                                existing Google Pay account, or your preferred
                                payment method.
                            </div>
                        </StyledBulletPointWrapper>
                    </UniOfferInstructionsListContainer>
                </>
            ),
            TermsAndConditionsComponent: (
                <StyledSubscriptionCopyArea>
                    <StyledSubscriptionCopyTitle>
                        <SemiboldText>
                            Subscribe with Google – University Student
                            Subscription Offer Terms.
                        </SemiboldText>
                    </StyledSubscriptionCopyTitle>
                    With this subscription offer, valid from 31.03.25 to
                    31.12.25, your subscription will be charged at $1.00 every 4
                    weeks for the first 12 months. After 12 months, your
                    subscription will be charged at full price of $8.00 per
                    week. To be eligible for this offer you must: (a) be a
                    current student at one of the following Western Australian
                    Universities: Curtin, Edith Cowan, Notre Dame, Murdoch or
                    The University of Western Australia; (b) be a new
                    subscriber; (c) not share the same household as another
                    subscriber who has cancelled any subscription in the 40 days
                    prior to the date you apply for this offer; and (d) have
                    devices that meet the following minimum technical
                    requirements: iOS requirement of 11.0 and Android minimum
                    requirement of 5.0. Subscriptions last for a minimum of 12
                    months and after that you can cancel at any time by emailing
                    subscriptions@wanews.com.au or calling 1800 811 855. Offers
                    are subject to our standard{' '}
                    <StyledTextLink
                        href={
                            'https://subscriber.thewest.com.au/subscription-terms'
                        }
                    >
                        Subscription Terms and Conditions
                    </StyledTextLink>{' '}
                    and the standard{' '}
                    <StyledTextLink
                        href={
                            'https://payments.google.com/payments/apis-secure/get_legal_document?ldo=0&ldt=googlepaytos&ldl=en-GB'
                        }
                    >
                        Subscribe with Google Terms.
                    </StyledTextLink>{' '}
                    Payment is by Direct Debit at 4-week intervals in accordance
                    with our Direct Debit Agreement, with the first payment
                    payable on day 1 of your subscription term. See{' '}
                    <StyledTextLink
                        href={
                            'https://subscriber.thewest.com.au/subscribe-with-google-faq'
                        }
                    >
                        Subscribe with Google with Google FAQs.
                    </StyledTextLink>
                </StyledSubscriptionCopyArea>
            ),
            InfoComponent: () => <></>,
        }
    }
