import styled from '@emotion/styled'
import { breakpoint } from '../../__styling'

export const StyledSportsMasterclassPromoCard = styled.div({
    display: 'block',
    minHeight: 170,
    position: 'relative',
    alignSelf: 'center',
    paddingBottom: '1.5rem',

    [breakpoint('xxs')]: {
        minHeight: 130,
    },

    [breakpoint('xs')]: {
        minHeight: 'auto',
    },

    [breakpoint('md')]: {
        display: 'block',
        height: '100%',
    },
})

export const StyledPromoImg = styled.img({
    width: '100%',
    height: '100%',
})
